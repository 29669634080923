import { Button, Descriptions, Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useHasPermission from '../../hooks/useHasPermission';
import {
  useAssistance,
  useCheckupType,
  useDelay,
  usePathology,
} from '../../redux/metadata/selectors';
import { useStep } from '../../redux/step/selectors';

function MedicalFileInfos({ medicalFile }) {
  const { t } = useTranslation();
  const history = useHistory();
  const hasPermission = useHasPermission();
  const pathologies = usePathology();
  const checkupTypes = useCheckupType();
  const delay = useDelay();
  const assistance = useAssistance();
  const [step] = useStep();
  const { interlocutors, reference, payment, traveldata } = medicalFile;

  const findInterlocutor = (type) => interlocutors.find((i) => i.type === type);

  const ld = findInterlocutor('Local Doctor');
  const ed = findInterlocutor('Expert Doctor');
  const ic = findInterlocutor('Intermediate Coordinator');
  const cc = findInterlocutor('Clinic Coordinator');
  const pc = findInterlocutor('Admin');
  const viewers = interlocutors.filter((i) => i.type === 'Viewer');
  return (
    <Descriptions bordered>
      <Descriptions.Item label={t('FRONT_MEDICAL_FILE_REFERENCE')} span={3}>
        {reference}
      </Descriptions.Item>

      {medicalFile.internal_reference_local && (
        <Descriptions.Item label={t('FRONT_MEDICAL_FILE_LOCAL_REFERENCE')} span={3}>
          {medicalFile.internal_reference_local}
        </Descriptions.Item>
      )}

      {medicalFile.internal_reference_hub && (
        <Descriptions.Item label={t('FRONT_MEDICAL_FILE_HUB_REFERENCE')} span={3}>
          {medicalFile.internal_reference_hub}
        </Descriptions.Item>
      )}

      {hasPermission('update:medicalfiles:pathology') && medicalFile.type < 2 && (
        <Descriptions.Item label={t('FRONT_MEDICAL_FILE_PATHOLOGY')} span={3}>
          {pathologies.find((p) => p.id === medicalFile.pathology).name}
        </Descriptions.Item>
      )}

      {medicalFile.type === 2 && (
        <Descriptions.Item label={t('FRONT_MEDICAL_FILE_CHECKUPTYPE')} span={3}>
          {checkupTypes.find((p) => p.id === medicalFile.checkup_type).name}
        </Descriptions.Item>
      )}

      {payment !== null && (
        <Descriptions.Item label={t('FRONT_MEDICAL_FILE_PAYMENT')} span={3}>
          {payment.amount} {payment.currency}s
        </Descriptions.Item>
      )}
      {traveldata !== null && (
        <>
          <Descriptions.Item label={t('FRONT_MEDICALE_FILE_AMOUNT')} span={3}>
            {traveldata.amount} {traveldata.currency}s
          </Descriptions.Item>
          <Descriptions.Item label={t('FRONT_MEDICALE_FILE_HOSPITAL')} span={3}>
            {traveldata.hospital}
          </Descriptions.Item>
          {traveldata.start && (
            <Descriptions.Item
              label={
                medicalFile.type === 1
                  ? t('FRONT_MEDICALE_FILE_START')
                  : t('FRONT_MEDICALE_FILE_START_CHECKUP')
              }
              span={3}
            >
              {new Date(traveldata.start).toLocaleDateString()}
            </Descriptions.Item>
          )}

          {traveldata.meetup && (
            <Descriptions.Item label={t('FRONT_MEDICALE_FILE_START')} span={3}>
              <Space space="large">
                <span>
                  {`${t('FRONT_COMMON_FROM')} ${new Date(
                    traveldata.event.start_time
                  ).toLocaleString()} ${t('FRONT_COMMON_UNTIL')} ${new Date(
                    traveldata.event.end_time
                  ).toLocaleString()}`}
                </span>
                <Button
                  type="danger"
                  onClick={() => history.push(`/medicals/${medicalFile.reference}/calendly`)}
                >
                  Annuler le RDV
                </Button>
              </Space>
            </Descriptions.Item>
          )}
          {traveldata.exit && (
            <Descriptions.Item label={t('FRONT_MEDICALE_FILE_EXIT')} span={3}>
              {new Date(traveldata.exit).toLocaleDateString()}
            </Descriptions.Item>
          )}
        </>
      )}
      {step && (
        <Descriptions.Item label={t('FRONT_MEDICAL_FILE_STATUS')} span={3}>
          {step.description}
        </Descriptions.Item>
      )}
      {medicalFile.delay !== null && (
        <Descriptions.Item
          label={
            medicalFile.type <= 1
              ? t('FRONT_MEDICAL_FILE_DELAY')
              : t('FRONT_MEDICAL_FILE_DELAY_CHECKUP')
          }
          span={3}
        >
          {delay[medicalFile.delay]}
        </Descriptions.Item>
      )}
      {medicalFile.assistance !== null && (
        <Descriptions.Item label={t('FRONT_MEDICAL_FILE_ASSISTANCE')} span={3}>
          {assistance[medicalFile.assistance]}
        </Descriptions.Item>
      )}
      {medicalFile.recoverystay !== null && (
        <Descriptions.Item label={t('FRONT_MEDICAL_FILE_RECOVERYSTAY')} span={3}>
          {medicalFile.recoverystay ? t('FRONT_COMMON_TRUE') : t('FRONT_COMMON_FALSE')}
        </Descriptions.Item>
      )}
      {medicalFile.visa !== null && (
        <Descriptions.Item label={t('FRONT_MEDICAL_FILE_VISA')} span={3}>
          {medicalFile.visa ? t('FRONT_COMMON_TRUE') : t('FRONT_COMMON_FALSE')}
        </Descriptions.Item>
      )}
      {medicalFile.region !== null && (
        <Descriptions.Item label={t('FRONT_MEDICAL_FILE_REGION')} span={3}>
          {medicalFile.region}
        </Descriptions.Item>
      )}
      {ld && (
        <Descriptions.Item label={t('FRONT_ROLE_LOCAL_DOCTOR')} span={3}>
          {ld.interlocutor?.name}
        </Descriptions.Item>
      )}
      {pc && (
        <Descriptions.Item label={t('FRONT_ROLE_PRINCIPAL_COORDINATOR')} span={3}>
          {pc.interlocutor?.name}
        </Descriptions.Item>
      )}
      {ic && (
        <Descriptions.Item label={t('FRONT_ROLE_INTERMEDIATE_COORDINATOR')} span={3}>
          {ic.interlocutor?.name}
        </Descriptions.Item>
      )}
      {cc && (
        <Descriptions.Item label={t('FRONT_ROLE_CLINIC_COORDINATOR')} span={3}>
          {cc.interlocutor?.name}
        </Descriptions.Item>
      )}
      {ed && (
        <Descriptions.Item label={t('FRONT_ROLE_EXPERT_DOCTOR')} span={3}>
          {ed.interlocutor?.name}
        </Descriptions.Item>
      )}
      {viewers.length > 0 && (
        <Descriptions.Item label={t('FRONT_ROLE_EXPERT_DOCTOR_VIEWER')} span={3}>
          {viewers
            .map(({ interlocutor }) => `${interlocutor.firstname} ${interlocutor.lastname}`)
            .join(', ')}
        </Descriptions.Item>
      )}
    </Descriptions>
  );
}

MedicalFileInfos.propTypes = {
  medicalFile: PropTypes.objectOf(PropTypes.any),
};

MedicalFileInfos.defaultProps = {
  medicalFile: null,
};

export default MedicalFileInfos;
