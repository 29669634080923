import { Button, Collapse, Form, Input, message, Select, Switch } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Flex from '../../components/Flex';
import FormErrors from '../../components/form/FormErrors';
import Logo from '../../components/form/Logo';
import MobilePhone from '../../components/form/MobilePhone';
import Loading from '../../components/layouts/Loading';
import { useCheckupType, useCountries } from '../../redux/metadata/selectors';
import {
  createCompany,
  fetchCalendarCompany,
  fetchCompany,
  updateCalendarCompany,
  updateCompany,
} from '../../services/api';

function EntityCreate() {
  const { t } = useTranslation();
  const formRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const [[isFetching, errors], setState] = useState([false, null]);
  const [company, setCompany] = useState(null);
  const countries = useCountries();
  const checkupTypes = useCheckupType();

  const s = location.state?.company;

  const fetchCompanyData = useCallback(async () => {
    if (s) {
      const data = await fetchCompany(s.id);
      try {
        const calendarUrls = await fetchCalendarCompany(s.id);
        if (calendarUrls)
          calendarUrls.map((url) => {
            data[`calendar_url_${url.id}`] = url.value;
            return url;
          });
      } catch (error) {
        console.log('error: ', error);
        return null;
      }
      if (data.logo) {
        const base64Response = await fetch(`data:image/png;base64,${data.logo}`);
        const blob = await base64Response.blob();
        data.logo = [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: URL.createObjectURL(blob),
            file: new File([blob], 'image.png'),
          },
        ];
      } else {
        data.logo = [];
      }
      return data;
    }
    return null;
  }, [s]);

  useEffect(() => {
    fetchCompanyData().then((data) => setCompany(data));
  }, [fetchCompanyData]);

  const create = async (values) => {
    setState([true, null]);
    const logo =
      values.logo && values.logo.length > 0
        ? values.logo[0].originFileObj || values.logo[0].file
        : null;
    const calendarUrls = [];
    Object.keys(values).map((key) => {
      if (key.includes('calendar_url') && values[key]) {
        const keySplited = key.split('_');
        const id = keySplited.pop();
        calendarUrls.push({
          id,
          value: values[key],
        });
      }
      return key;
    });
    const booleanFields = ['smo', 't4t', 'checkup', 'online_checkup', 'dermatology'];

    const data = {
      ...values,
      ...Object.fromEntries(booleanFields.map((field) => [field, values[field] ? 1 : 0])),
      logo,
    };
    try {
      if (s) {
        await updateCompany(s.id, data);
        if (calendarUrls.length > 0) await updateCalendarCompany(s.id, calendarUrls);
        message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));
        await fetchCompany(s.id);
      } else {
        const newCompany = await createCompany(data);
        if (calendarUrls.length > 0) await updateCalendarCompany(newCompany.data.id, calendarUrls);
        message.success(t('FRONT_NOTIFICATION_SAVE_SUCCESS'));
        history.push('/entities');
      }
      setState([false, null]);
    } catch (err) {
      setState([false, err]);
    }
  };

  if (s && company === null) return <Loading />;
  return (
    <div>
      <Title>{t('FRONT_ENTITY_CREATE')}</Title>
      <Form
        ref={formRef}
        layout="vertical"
        name="profile"
        onFinish={create}
        initialValues={company}
        scrollToFirstError
      >
        <Form.Item
          label={t('FRONT_ENTITY_NAME')}
          name="name"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('FRONT_ENTITY_EMAIL')}
          name="email"
          rules={[{ type: 'email', required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>
        <Collapse style={{ marginBottom: 20 }}>
          <Collapse.Panel header={t('FRONT_ENTITIES_CALENDLY')} key={1}>
            {checkupTypes.map((p, i) => (
              <Form.Item key={p.id} label={`Lien pour ${p.name}`} name={`calendar_url_${p.id}`}>
                <Input />
              </Form.Item>
            ))}
          </Collapse.Panel>
        </Collapse>

        <Form.Item label={t('FRONT_ENTITY_MAIN_COORDINATOR_EMAIL')} name="main_coordinator">
          <Input />
        </Form.Item>

        <MobilePhone />

        <Form.Item
          label={t('FRONT_USER_ADDRESS')}
          name="address"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('FRONT_USER_ZIP')}
          name="zipcode"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('FRONT_USER_CITY')}
          name="city"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('FRONT_ENTITY_COUNTRY')}
          name="country"
          rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
        >
          <Select
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
          >
            {countries.map((c) => (
              <Select.Option key={`country-${c.id}`} value={c.code}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Flex style={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}>
          <Form.Item
            style={{ marginRight: 20 }}
            label={t('FRONT_MEDICAL_FILES_SMO_TITLE')}
            name="smo"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            style={{ marginRight: 20 }}
            label={t('FRONT_MEDICAL_FILES_T4T_TITLE')}
            name="t4t"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            style={{ marginRight: 20 }}
            label={t('FRONT_MEDICAL_FILES_HC_TITLE')}
            name="checkup"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            style={{ marginRight: 20 }}
            label={t('FRONT_MEDICAL_FILES_OC_TITLE')}
            name="online_checkup"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            style={{ marginRight: 20 }}
            label={t('FRONT_MEDICAL_FILES_DERMATO_TITLE')}
            name="dermatology"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Flex>
        <Logo />

        <FormErrors err={errors} />

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isFetching}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default EntityCreate;
