import { Form, Select, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { fetchAllCompanies } from '../../services/api';

function SelectCompanies({ checkupType }) {
  const { t } = useTranslation();
  const [state, setState] = useState({ companies: [], isFetching: true });

  useEffect(() => {
    fetchAllCompanies(checkupType).then((c) => {
      setState({ companies: c, isFetching: false });
    });
    return () => {
      setState({ companies: [], isFetching: true });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isFetching) return <Skeleton />;

  return (
    <Form.Item
      label={t('FRONT_COMPANY_LABEL')}
      name="company_id"
      rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
    >
      <Select
        showSearch
        filterOption={(input, option) =>
          option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {state.companies.map((m) => (
          <Select.Option key={`company-${m.id}`} value={m.id}>
            {m.name} à {m.city}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

SelectCompanies.propTypes = {
  checkupType: PropTypes.number,
};

SelectCompanies.defaultProps = {
  checkupType: null,
};
export default SelectCompanies;
