import { PlusOutlined } from '@ant-design/icons';
import { Form, Upload } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function Logo(props) {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    if (src) {
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow.document.write(image.outerHTML);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    const files = e && e.fileList.length === 0 ? [] : [e.fileList[e.fileList.length - 1]];
    setFileList(files);
    return files;
  };

  return (
    <Form.Item
      label={t('FRONT_ENTITY_LOGO')}
      name="logo"
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      valuePropName="fileList"
      getValueFromEvent={normFile}
      rules={[
        {
          required: false,
          message: `${t('FRONT_ENTITY_LOGO')} ${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
        },
      ]}
      /* eslint-disable react/jsx-props-no-spreading */
      {...props}
    >
      <Upload
        name="logo"
        action="/upload.do"
        accept="image/png, image/gif, image/jpeg"
        beforeUpload={() => false}
        listType="picture-card"
        onPreview={onPreview}
      >
        {fileList.length === 0 && (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        )}
      </Upload>
    </Form.Item>
  );
}

export default Logo;
