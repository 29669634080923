import React from 'react';
import PropTypes from 'prop-types';

import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import Item from './item';
import logoSrc from '../../../assets/images/logo.png';
import Chart from './chart';

import arialFont from '../../../assets/fonts/Arial.ttf';
import arialBoldFont from '../../../assets/fonts/ArialBold.ttf';
import ArialItalicFont from '../../../assets/fonts/ArialItalic.ttf';

Font.register({
  family: 'Arial',
  fonts: [
    { src: arialFont },
    { src: arialBoldFont, fontWeight: 'bold' },
    { src: ArialItalicFont, fontStyle: 'italic' },
  ],
});

export const styles = StyleSheet.create({
  page: {
    fontFamily: 'Arial',
    fontSize: 10,
    textAlign: 'center',
    padding: 24,
    color: '#010090',
  },
  title: { fontSize: 24, fontWeight: 'bold' },
  subtitle: { fontSize: '12', textAlign: 'justify', fontStyle: 'italic' },
  logo: { height: 45, width: 140 },
  header: {
    backgroundColor: '#2cb6b9',
    color: '#ffffff',
    padding: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const Summary = (props) => {
  const { items = [] } = props;
  return (
    <Document title="Synthèse du bilan digitalisé" author="France Surgery">
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>Bilan digitalisé</Text>
          <Image style={styles.logo} src={logoSrc} />
        </View>
        <View style={{ padding: '24' }}>
          <Text style={styles.subtitle}>
            Ce test a pour objectif de vous sensibiliser sur votre propre propre situation santé et
            modes de vie, ainsi que de vous conseiller avec des actions de prévention simples, à
            réaliser pour agir. Ce rapport n’est pas un diagnostic et nous vous encourageons à
            consulter un médecin traitant e doit être privilégié pour toutes les situations
            spécifiques.
          </Text>
          {items.length > 0 && (
            <>
              <Chart dots={items} gender={items[0].gender} />
              {items.map((item, key) => (
                <Item
                  key={`item_${key}`}
                  label={item.label}
                  title={item.title}
                  color={item.color}
                  text={item.text}
                />
              ))}
            </>
          )}
        </View>
      </Page>
    </Document>
  );
};

Summary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Summary;
