/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/aria-role */
import { Button, Collapse, Form, message, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useHasPermission from '../../hooks/useHasPermission';
import { useRoles as useAllRoles } from '../../redux/metadata/selectors';
import { nextStep } from '../../redux/step/actions';
import { useStep } from '../../redux/step/selectors';
import { useRoles } from '../../redux/user/selectors';
import request from '../../services/request';
import { isExpertDoctor } from '../../services/rights';
import FormErrors from '../form/FormErrors';
import Interlocutor from '../form/Interlocutor';
import SelectCheckupType from '../form/SelectCheckupType';
import SelectPathology from '../form/SelectPathology';
import SelectStep from '../form/SelectStep';
import T4T from '../form/T4T';
import CalendlyForm from './CalendlyForm';
import PaymentForm from './PaymentForm';
import TravelDataForm from './TravelDataForm';

const styles = {
  wrapper: { flexDirection: 'row', alignItems: 'baseline' },
  switch: { marginLeft: 16 },
};

function MedicalFileEdit({ medicalFile, onFinish }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [step] = useStep();
  const roles = useRoles();
  const allRoles = useAllRoles();
  const hasPermission = useHasPermission();
  const [[steps, stepsAreFetching], setSteps] = useState([[], true]);
  const [[defaultInterlocutors, isFetching], setDefaultInterlocutors] = useState([{}, true]);
  const [[isUpdating, updateErr], setIsUpdating] = useState([false, null]);

  const initInterlocutorsForm = () => {
    let temp = { Viewer: [] };
    medicalFile.interlocutors.map((i) => {
      if (i.type === 'Viewer') {
        temp = { ...temp, Viewer: [...temp.Viewer, i.interlocutor.id] };
      } else {
        temp = { ...temp, [i.type]: i.interlocutor.id };
      }
      return temp;
    });
    setDefaultInterlocutors([{ ...defaultInterlocutors, ...temp }, false]);
  };

  const fetchSteps = useCallback(() => {
    request(`/medicalfiles/${medicalFile.reference}/steps`)
      .then((s) => setSteps([s, false]))
      .catch((err) => setSteps([[], false]));
  }, [medicalFile]);

  useEffect(() => {
    initInterlocutorsForm();
    fetchSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSteps]);

  const updateInterlocutors = async (_interlocutors) => {
    setIsUpdating([true, null]);
    const interlocutors = Object.keys(_interlocutors).reduce((acc, key) => {
      const roleName = key === 'Viewer' ? 'Expert Doctor' : key;
      const role = allRoles.find((r) => r.name === roleName);
      if (Array.isArray(_interlocutors[key])) {
        _interlocutors[key].map((id) =>
          acc.push({
            role: key,
            type: role.id,
            user_id: id,
            viewer: true,
          })
        );
      } else {
        acc.push({
          type: role.id,
          role: key,
          user_id: _interlocutors[key],
        });
      }
      return acc;
    }, []);

    const interlocutorsToAdd = interlocutors.filter((i) => {
      if (!i.user_id) return false;
      return (
        medicalFile.interlocutors.findIndex(
          (i2) => i.role === i2.type && i.user_id === i2.interlocutor.id
        ) === -1
      );
    });

    const interlocutorsToRemove = medicalFile.interlocutors.filter((i) => {
      if (i.type === 'Patient') return false;
      return (
        interlocutors.findIndex(
          (i2) => i2.user_id && i2.user_id === i.interlocutor.id && i.type === i2.role
        ) === -1
      );
    });

    await Promise.all(
      interlocutorsToAdd.map((i) =>
        request(`/medicalfiles/${medicalFile.reference}/interlocutors`, 'POST', i)
      ),
      interlocutorsToRemove.map((i) =>
        request(`/medicalfiles/${medicalFile.reference}/interlocutors/${i.id}`, 'DELETE')
      )
    )
      .then(() => {
        setIsUpdating([false, null]);
        message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));
        dispatch(nextStep(medicalFile.reference));
        onFinish();
      })
      .catch((err) => {
        message.error(t('FRONT_NOTIFICATION_UPDATE_FAILED'));
        setIsUpdating([false, err]);
      });
  };

  const update = async (values) => {
    setIsUpdating([true, null]);
    return request(`/medicalfiles/${medicalFile.reference}`, 'PATCH', values)
      .then(() => {
        if (
          step.conditions_met &&
          step.can_validate &&
          step.reference !== 'patient.stepvalidatecommercialproposition'
        )
          dispatch(nextStep(medicalFile.reference));
        setIsUpdating([false, null]);
        message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));
        onFinish();
      })
      .catch((err) => {
        message.error(t('FRONT_NOTIFICATION_UPDATE_FAILED'));
        setIsUpdating([false, err]);
      });
  };

  if (stepsAreFetching || isFetching) return <Skeleton />;

  return (
    <>
      <Form
        name="medical_file"
        layout="vertical"
        initialValues={{ ...medicalFile, step: steps.indexOf(medicalFile.step.description) }}
        onFinish={update}
        scrollToFirstError
      >
        {hasPermission('update:medicalfiles:pathology') && medicalFile.type < 2 && (
          <SelectPathology />
        )}

        {medicalFile.type === 2 && <SelectCheckupType />}

        {hasPermission('update:medicalfiles:step') && <SelectStep steps={steps} />}

        {medicalFile.type === 1 && !isExpertDoctor(roles) && <T4T />}

        <FormErrors err={updateErr} />

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isUpdating}>
            {t('FRONT_FORM_SAVE')}
          </Button>
        </Form.Item>
      </Form>
      <Collapse style={{ marginBottom: 20 }}>
        <Collapse.Panel
          header={t('FRONT_MEDICALE_FILE_INTERLOCUTORS')}
          key={1}
          collapsible={isExpertDoctor(roles)}
        >
          <Form onFinish={(v) => updateInterlocutors(v)} initialValues={defaultInterlocutors}>
            {hasPermission('assign Role Admin') && (
              <Interlocutor label={t('FRONT_ROLE_PRINCIPAL_COORDINATOR')} roleName="Admin" />
            )}

            {hasPermission('assign Role Local Doctor') && (
              <Interlocutor label={t('FRONT_ROLE_LOCAL_DOCTOR')} roleName="Local Doctor" />
            )}

            {hasPermission('assign Role Clinic Coordinator') && medicalFile.type >= 1 && (
              <Interlocutor
                label={t('FRONT_ROLE_CLINIC_COORDINATOR')}
                roleName="Clinic Coordinator"
              />
            )}

            {hasPermission('assign Role Intermediate Coordinator') && (
              <Interlocutor
                label={t('FRONT_ROLE_INTERMEDIATE_COORDINATOR')}
                roleName="Intermediate Coordinator"
              />
            )}

            {hasPermission('assign Role Expert Doctor') && (
              <Interlocutor label={t('FRONT_ROLE_EXPERT_DOCTOR')} roleName="Expert Doctor" />
            )}

            {hasPermission('assign Role Expert Doctor') && (
              <Interlocutor
                label={t('FRONT_ROLE_EXPERT_DOCTOR_VIEWER')}
                roleName="Expert Doctor"
                type="Viewer"
                multiple
              />
            )}
            <Button type="primary" htmlType="submit" loading={isUpdating}>
              {t('FRONT_FORM_SAVE')}
            </Button>
          </Form>
        </Collapse.Panel>
        {medicalFile.type >= 1 && (
          <Collapse.Panel
            header={
              medicalFile.type === 1
                ? t('FRONT_MEDICAL_FILE_TRAVEL_DATA')
                : t('FRONT_MEDICAL_FILE_CHECKUP_DATA')
            }
            key={2}
            collapsible={isExpertDoctor(roles)}
          >
            <TravelDataForm medicalFile={medicalFile} />
            {medicalFile.type !== 1 && <CalendlyForm />}
          </Collapse.Panel>
        )}
        {hasPermission('create:payments') && (
          <Collapse.Panel header={t('FRONT_MEDICAL_FILE_PAYMENT')} key={3}>
            <PaymentForm medicalFile={medicalFile} />
          </Collapse.Panel>
        )}
      </Collapse>
    </>
  );
}

MedicalFileEdit.propTypes = {
  medicalFile: PropTypes.objectOf(PropTypes.any).isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default MedicalFileEdit;
