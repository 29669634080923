import { Card, Col, Row } from 'antd';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import SMOImage from '../../assets/images/SMO.jpg';
import OCImage from '../../assets/images/OC.png';
import T4TImage from '../../assets/images/T4T.jpg';
import HCImage from '../../assets/images/HC.jpg';
import DermatoImage from '../../assets/images/Dermato.jpg';
import { useUser } from '../../redux/user/selectors';

function SelectMedicalFileType({ onChange }) {
  const { t } = useTranslation();
  const [user] = useUser();

  const handleClick = (val) => {
    onChange?.(val);
  };
  return (
    <Row gutter={[16, 16]} align="stretch">
      {user.company.smo && (
        <Col sm={24} md={12}>
          <MedicalFileTypeCard
            title={<Trans i18nKey="FRONT_MEDICAL_FILES_SMO_TITLE" />}
            description={<Trans i18nKey="FRONT_MEDICAL_FILES_SMO_DESCRIPTION" />}
            backgroundImage={SMOImage}
            action={<Trans i18nKey="FRONT_MEDICAL_FILES_SMO_ACTION" />}
            onClick={() => handleClick(0)}
          />
        </Col>
      )}
      {user.company.t4t && (
        <Col sm={24} md={12}>
          <MedicalFileTypeCard
            title={<Trans i18nKey="FRONT_MEDICAL_FILES_T4T_TITLE" />}
            description={<Trans i18nKey="FRONT_MEDICAL_FILES_T4T_DESCRIPTION" />}
            backgroundImage={T4TImage}
            action={<Trans i18nKey="FRONT_MEDICAL_FILES_T4T_ACTION" />}
            onClick={() => handleClick(1)}
          />
        </Col>
      )}
      {user.company.checkup && (
        <Col sm={24} md={12}>
          <MedicalFileTypeCard
            title={<Trans i18nKey="FRONT_MEDICAL_FILES_HC_TITLE" />}
            description={<Trans i18nKey="FRONT_MEDICAL_FILES_HC_DESCRIPTION" />}
            backgroundImage={HCImage}
            action={<Trans i18nKey="FRONT_MEDICAL_FILES_HC_ACTION" />}
            onClick={() => handleClick(2)}
          />
        </Col>
      )}
      {user.company.dermatology && (
        <Col sm={24} md={12}>
          <MedicalFileTypeCard
            title={<Trans i18nKey="FRONT_MEDICAL_FILES_DERMATO_TITLE" />}
            description={<Trans i18nKey="FRONT_MEDICAL_FILES_DERMATO_DESCRIPTION" />}
            backgroundImage={DermatoImage}
            action={<Trans i18nKey="FRONT_MEDICAL_FILES_DERMATO_ACTION" />}
            onClick={() => handleClick(3)}
          />
        </Col>
      )}

      {user.company.online_checkup && (
        <Col sm={24} md={12}>
          <MedicalFileTypeCard
            title={<Trans i18nKey="FRONT_MEDICAL_FILES_OC_TITLE" />}
            description={<Trans i18nKey="FRONT_MEDICAL_FILES_OC_DESCRIPTION" />}
            backgroundImage={OCImage}
            action={<Trans i18nKey="FRONT_MEDICAL_FILES_OC_ACTION" />}
            onClick={() => handleClick(4)}
          />
        </Col>
      )}
    </Row>
  );
}

export default SelectMedicalFileType;

SelectMedicalFileType.propTypes = {
  onChange: PropTypes.func.isRequired,
};

function MedicalFileTypeCard({ title, description, backgroundImage, action, onClick }) {
  return (
    <Card
      hoverable
      key={Math.random()}
      className="card__medicalfiletype"
      cover={<img src={backgroundImage} alt={title} className="card__medicalfiletype__cover" />}
      bodyStyle={{ flexGrow: 1 }}
      onClick={onClick}
      actions={[
        <div
          type="link"
          className="card__medicalfiletype__action"
          style={{ fontSize: 16, lineHeight: 1 }}
        >
          <Text style={{ maxWidth: '100%' }} ellipsis>
            {action}
          </Text>
        </div>,
      ]}
    >
      <Card.Meta title={title} description={description} />
    </Card>
  );
}

MedicalFileTypeCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
