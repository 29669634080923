import { FileProtectOutlined, UserOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useStepActive from '../../../hooks/useOCStepActive';

const { TabPane } = Tabs;

function HCTabs() {
  const { t } = useTranslation();

  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const { isStep } = useStepActive();
  return (
    <Tabs activeKey={location.pathname} onChange={(key) => history.push(key)}>
      <TabPane
        tab={
          <>
            <FileProtectOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_PATIENT')}
          </>
        }
        key={`/medicals/${params.reference}/patient`}
      />
      <TabPane
        tab={
          <>
            <FileProtectOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_MEDICAL_FILE')}
          </>
        }
        key={`/medicals/${params.reference}`}
      />
      <TabPane
        tab={
          <>
            <UserOutlined />
            {t('FRONT_MEDICAL_FILE_TABS_HEALTH_FORM')}
          </>
        }
        key={`/medicals/${params.reference}/health`}
      />
      {isStep('system.fileclosed') && (
        <TabPane
          tab={
            <>
              <UserOutlined />
              {t('FRONT_MEDICAL_FILE_TABS_PDF')}
            </>
          }
          key={`/medicals/${params.reference}/summary`}
        />
      )}
    </Tabs>
  );
}

export default HCTabs;
