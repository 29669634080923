/* eslint-disable react/prop-types */
import { PDFViewer } from '@react-pdf/renderer';
import { Skeleton } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchSummary } from '../../services/api';
import Document from '../../components/medicalFile/summary/base';

function Summary({ medicalFile }) {
  const { t } = useTranslation();
  const [state, setState] = useState({ questions: [], responses: [], isFetching: true });

  const { reference, id } = medicalFile;

  const fetchSummaryData = useCallback(async () => {
    try {
      const items = await fetchSummary(reference);
      setState({ items, isFetching: false });
    } catch {
      setState({ items: [], isFetching: false });
    }
  }, [reference]);

  useEffect(() => {
    fetchSummaryData();
  }, [fetchSummaryData]);

  if (state.isFetching) return <Skeleton />;
  if (!reference) return null;

  return (
    <div>
      <Title>{t('FRONT_SUMMARY_LABEL')}</Title>
      <PDFViewer style={{ width: '100%', height: '100vh' }}>
        <Document items={state.items} />
      </PDFViewer>
    </div>
  );
}

export default Summary;
