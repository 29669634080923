import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchMedicalFileData } from '../../redux/medicalFile/actions';
import { useMedicalFile } from '../../redux/medicalFile/selectors';
import { SMORouter, SMOTabs, SMOWorkflow } from '../../components/workflow/SMO';
import { T4TRouter, T4TTabs, T4TWorkflow } from '../../components/workflow/T4T';
import { HCRouter, HCTabs, HCWorkflow } from '../../components/workflow/HC';
import { OCRouter, OCTabs, OCWorkflow } from '../../components/workflow/OC';
import { DermatoRouter, DermatoTabs, DermatoWorkflow } from '../../components/workflow/Dermato';

function MedicalFile() {
  const { t: tr } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const [medicalFile, isFetching] = useMedicalFile();

  useEffect(() => {
    dispatch(fetchMedicalFileData(params.reference));
  }, [params.reference, dispatch]);

  const [Workflow, Tabs, Router] = useMemo(() => {
    if (!medicalFile) return [];
    let w;
    let t;
    let r;
    switch (medicalFile.type) {
      case 0: // SMO
        w = SMOWorkflow;
        t = SMOTabs;
        r = SMORouter;
        break;
      case 1: // T4T
        w = T4TWorkflow;
        t = T4TTabs;
        r = T4TRouter;
        break;
      case 2: // HC
        w = HCWorkflow;
        t = HCTabs;
        r = HCRouter;
        break;
      case 3: // Dermato
        w = DermatoWorkflow;
        t = DermatoTabs;
        r = DermatoRouter;
        break;
      case 4: // OC
        w = OCWorkflow;
        t = OCTabs;
        r = OCRouter;
        break;
      default:
        w = SMOWorkflow;
        t = SMOTabs;
        r = SMORouter;
        break;
    }
    return [w, t, r];
  }, [medicalFile]);

  if (isFetching) return <Skeleton />;
  if (!medicalFile) return 'No medical file';

  return (
    <div>
      <Title>{`${tr('FRONT_MEDICAL_FILE_LABEL')} ${medicalFile.reference}`}</Title>
      <Workflow />
      <Tabs />
      <Router medicalFile={medicalFile} />
    </div>
  );
}

export default MedicalFile;
