import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Html from 'react-pdf-html';
import warningPngSrc from '../../../assets/images/alerte.png';
import goodPngSrc from '../../../assets/images/good.png';
import sadPngSrc from '../../../assets/images/triste.png';

const styles = StyleSheet.create({
  section: {
    width: '100%',
    textAlign: 'left',
    padding: '16 12 12 12',
    border: '1px solid #cac9e9',
    position: 'relative',
    fontSize: 10,
    marginBottom: 24,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    position: 'absolute',
    top: '-21px',
    padding: 10,
    left: 15,
    backgroundColor: '#ffffff',
  },
  alert: {
    display: 'flex',
    textAlign: 'left',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
    padding: 10,
    marginBottom: 16,
  },
  alertText: {
    flex: 1,
    fontSize: 10,
    marginLeft: 15,
    fontWeight: 'bold',
  },
  alertIcon: {
    color: '#ffffff',
    height: 30,
    width: 25,
  },
  recoTitle: {
    fontSize: 12,
    height: 'auto',
    marginBottom: 4,
    fontWeight: 'bold',
  },
  recoText: {
    fontSize: 10,
  },
});

const backgroundColors = {
  red: '#cf4419',
  orange: '#ffe0cc',
  green: '#d8ece0',
};

const icons = {
  red: sadPngSrc,
  orange: warningPngSrc,
  green: goodPngSrc,
};

const Item = (props) => {
  const { title, color, text, label } = props;
  return (
    <View style={styles.section} wrap={false}>
      {label && <Text style={styles.title}>{label}</Text>}
      {title && (
        <View style={[styles.alert, { backgroundColor: backgroundColors[color] }]}>
          <Image style={styles.alertIcon} src={icons[color]} />
          <Text style={styles.alertText}>{title}</Text>
        </View>
      )}
      {text !== '' && (
        <View>
          <Text style={styles.recoTitle}>Recommandation</Text>
          <Html style={styles.recoText}>{text}</Html>
        </View>
      )}
    </View>
  );
};

Item.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Item;
