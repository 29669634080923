import { Form, Select, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import request from '../../services/request';

function SelectCenters({ checkupType, city }) {
  const { t } = useTranslation();
  const [state, setState] = useState({ centers: [], isFetching: true });

  useEffect(() => {
    request(`/checkup/centers?filter[city]=${city}&filter[type]=${checkupType}`).then((r) => {
      setState({ centers: r.data.filter((c) => c.company_id !== null), isFetching: false });
    });
    return () => {
      setState({ centers: [], isFetching: true });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkupType, city]);

  if (state.isFetching) return <Skeleton />;
  if (state.centers.length === 0)
    return (
      <p>
        Aucun centre ne prend en compte ce type de bilan dans cette ville. Veuillez sélectionner une
        autre ville
      </p>
    );
  return (
    <Form.Item
      label={t('FRONT_CENTER_LABEL')}
      name="center_id"
      rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
    >
      <Select
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {state.centers.map((m, i) => (
          <Select.Option key={`center-${i}`} value={m.company_id}>
            {m.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

SelectCenters.propTypes = {
  checkupType: PropTypes.number,
  city: PropTypes.string,
};

SelectCenters.defaultProps = {
  checkupType: null,
  city: '',
};
export default SelectCenters;
