/* eslint-disable no-unreachable */
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import { Document, Page, StyleSheet, Text, View, pdf } from '@react-pdf/renderer';
import { Button, Form, Skeleton, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useStepActive from '../../hooks/useSMOStepActive';
import { fetchStep } from '../../redux/step/actions';
import { createHealthAnswers, fetchHealthAnswers, fetchHealthQuestions } from '../../services/api';
import { groupBy } from '../../services/helper';
import Flex from '../Flex';
import FormDynamic from '../form/FormDynamic';
import FormErrors from '../form/FormErrors';
import FormInfos from '../infos/FormInfos';

// PDF styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
  },
  section: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
  },
  title: {
    fontSize: 18,
    fontWeight: 'heavy',
    padding: 5,
    color: 'white',
    backgroundColor: '#2CB6B9',
  },
  set: {
    paddingTop: 8,
    marginLeft: 5,
    marginRight: 5,
  },
  question: {
    fontSize: 11,
    fontWeight: 'heavy',
    color: 'black',
  },
  answer: {
    fontSize: 10,
    fontWeight: 400,
    paddingTop: 3,
    marginLeft: 5,
    color: '#333333',
  },
});

function HealthForm({ medicalFile }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [onEdit, setOnEdit] = useState(true);
  const [[isConverting, convertErr], setIsConverting] = useState([false, null]);
  const [[isSaving, saveErr], setIsSaving] = useState([false, null]);
  const [form, setForm] = useState(localStorage.getItem('health'));
  const [[answers, questions, isFetching], setState] = useState([[], [], true]);
  const { beforeStep } = useStepActive();
  const [formHook] = Form.useForm();

  const { reference, type } = medicalFile;

  const scrollOnTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const save = async (values) => {
    setIsSaving([true, null]);
    const arr = Object.entries(values)
      .filter(([, v]) => v !== undefined)
      .map(([k, v]) => [k, v.toString()]);
    const a = arr.map(([key, answer]) => ({ health_question_id: Number(key), answer }));
    try {
      await createHealthAnswers(reference, {
        medical_file_id: medicalFile.id,
        answers: a,
      });
      if (answers.length === 0) {
        dispatch(fetchStep(reference));
      }
      const newAnswers = await fetchHealthAnswers(reference);
      setState([newAnswers, questions, false]);
      setIsSaving([false, null]);
      setForm(null);
      // si c'est un bilan digitalisé on redirige vers la synthèse
      if (type === 3) history.push(`/medicals/${reference}/summary`);

      localStorage.removeItem('health');
      message.success(t('FRONT_NOTIFICATION_SAVE_SUCCESS'));
      scrollOnTop();
    } catch (err) {
      setIsSaving([false, err]);
      message.error(t('FRONT_NOTIFICATION_SAVE_FAILED'));
    }
  };

  const handleEdit = () => {
    setOnEdit(!onEdit);
    scrollOnTop();
  };

  const saveForm = (field) => {
    let formTemp = form === 'null' || form === null ? {} : form;
    if (typeof formTemp === 'string') formTemp = JSON.parse(formTemp);

    formTemp[field[0].name] = field[0].value === '' ? undefined : field[0].value;
    setForm(formTemp);

    if (form !== 'null' && typeof form === 'object')
      localStorage.setItem('health', JSON.stringify(form));
  };

  const convertToPdf = async () => {
    setIsConverting([true, null]);
    let values;
    if (onEdit) {
      values = formHook.getFieldsValue();
    } else {
      values = {};
      answers.forEach((ans) => {
        values[ans.health_question_id] = ans.answer;
      });
    }
    const pdfDoc = (
      <Document>
        {questions.map((cat, catIndex) => (
          <Page style={styles.page} key={`cat_${catIndex}`}>
            <View style={styles.section} key={`cat_${catIndex}`}>
              <Text style={styles.title}>{cat.category}</Text>
              {cat.groupBy.map((q) => {
                let answerString = values[q.id] || '-';
                if (q.type === 'boolean') {
                  answerString = answerString === '0' ? 'NON' : 'OUI';
                } else if (q.type === 'select') {
                  const answer = q.answers.find((e) => e.value === answerString);
                  answerString = answer ? answer.label : answerString;
                }
                return (
                  <View style={styles.set}>
                    <Text style={styles.question}>
                      {q.question.trim()} {q.required ? '(requis) ' : ''}:
                    </Text>
                    <Text style={styles.answer}>{answerString}</Text>
                  </View>
                );
              })}
            </View>
          </Page>
        ))}
      </Document>
    );
    try {
      const blob = await pdf(pdfDoc).toBlob();
      const pdfUrl = window.URL.createObjectURL(blob);
      window.open(pdfUrl, '_blank');
      setIsConverting([false, null]);
    } catch (e) {
      setIsConverting([false, e]);
    }
  };

  const fetchAnswersQuestions = useCallback(async () => {
    try {
      let q = await fetchHealthQuestions(reference);
      q = groupBy(q, 'category');
      const a = await fetchHealthAnswers(reference);

      setState([a, q, false]);
    } catch {
      setState([[], [], false]);
    }
  }, [reference]);

  useEffect(() => {
    fetchAnswersQuestions();
  }, [fetchAnswersQuestions]);

  useEffect(() => {
    if (answers.length > 0) {
      setOnEdit(false);
    }
  }, [answers]);

  if (isFetching) return <Skeleton />;

  return (
    <>
      {onEdit ? (
        <Form
          layout="vertical"
          name="health"
          form={formHook}
          onFinish={save}
          scrollToFirstError={{
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          }}
          onFieldsChange={saveForm}
        >
          {questions.map((cat) => (
            <div key={cat.id}>
              <Title level={4} className="medicalfiles__category">
                {cat.category}
              </Title>
              {cat.groupBy && <FormDynamic questions={cat.groupBy} responses={answers} />}
            </div>
          ))}

          <FormErrors err={saveErr} />

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {t('FRONT_FORM_SAVE')}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        questions.map((cat) => (
          <div key={cat.id}>
            <Title level={4} className="medicalfiles__category">
              {cat.category}
            </Title>
            <FormInfos questions={cat} answers={answers} />
          </div>
        ))
      )}
      <Button
        type="secondary"
        disabled={isSaving}
        onClick={() => {
          convertToPdf();
        }}
        loading={isConverting}
      >
        Exporter en PDF
      </Button>
      {convertErr && <span style={{ color: 'red', marginLeft: 15 }}>{convertErr}</span>}
      {answers.length > 0 && beforeStep('doctor.stepvalidatemedicalfile') && (
        <Flex style={{ justifyContent: 'flex-end' }}>
          <Button
            type={onEdit ? 'danger' : 'primary'}
            shape="round"
            icon={onEdit ? <ArrowLeftOutlined /> : <EditOutlined />}
            onClick={() => handleEdit()}
          />
        </Flex>
      )}
    </>
  );
}

HealthForm.propTypes = {
  medicalFile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default HealthForm;
