/* eslint-disable react/prop-types */
import { Button, DatePicker, Form, Input, InputNumber, Select, message } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchMedicalFileData } from '../../redux/medicalFile/actions';
import { fetchStep } from '../../redux/step/actions';
import request from '../../services/request';
import FormErrors from '../form/FormErrors';
import CalendlyForm from './CalendlyForm';

const format = 'HH:mm';

function TravelDataForm({ medicalFile }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [[isUpdating, updateErr], setIsUpdating] = useState([false, null]);

  const updateTravelData = async (values) => {
    setIsUpdating([true, null]);
    const payload = {
      ...values,
      start: moment(),
    };
    return request(`/medicalfiles/${medicalFile.reference}/traveldata`, 'POST', payload)
      .then(() => {
        setIsUpdating([false, null]);
        dispatch(fetchStep(medicalFile.reference));
        dispatch(fetchMedicalFileData(medicalFile.reference));
        message.success(t('FRONT_NOTIFICATION_UPDATE_SUCCESS'));
      })
      .catch((err) => {
        message.error(t('FRONT_NOTIFICATION_UPDATE_FAILED'));
        setIsUpdating([false, err]);
      });
  };

  return (
    <Form
      name="medical_file_traveldata"
      layout="vertical"
      form={form}
      initialValues={{
        ...medicalFile.traveldata,
        currency: 'euro',
      }}
      onFinish={updateTravelData}
      scrollToFirstError
    >
      <Form.Item label={t('FRONT_MEDICALE_FILE_AMOUNT')}>
        <Input.Group compact>
          <Form.Item name="amount" noStyle>
            <InputNumber />
          </Form.Item>
          <Form.Item name="currency" noStyle>
            <Select>
              <Select.Option value="euro">€</Select.Option>
              <Select.Option value="dollar">$</Select.Option>
            </Select>
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item
        label={t('FRONT_MEDICALE_FILE_HOSPITAL')}
        name="hospital"
        rules={[{ required: true, message: `${t('FRONT_FORM_MESSAGE_REQUIRED')}` }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('FRONT_MEDICALE_FILE_EXIT')}
        name="exit"
        getValueFromEvent={(v) => (v ? moment(v) : null)}
        rules={[
          {
            required: medicalFile.type === 1,
            message: `${t('FRONT_MEDICALE_FILE_EXIT')} ${t('FRONT_FORM_MESSAGE_REQUIRED')}`,
          },
        ]}
      >
        <DatePicker />
      </Form.Item>

      <FormErrors err={updateErr} />

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isUpdating}>
          {t('FRONT_FORM_SEND')}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default TravelDataForm;
