import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, message, Select } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import FormErrors from '../../components/form/FormErrors';
import SelectCheckupType from '../../components/form/SelectCheckupType';
import MedicalFileTypeRadio from '../../components/form/SelectMedicalFileType';
import SelectPathology from '../../components/form/SelectPathology';
import T4T from '../../components/form/T4T';
import useHasPermission from '../../hooks/useHasPermission';
import { useMedicaleFileType } from '../../redux/metadata/selectors';
import { useRoles, useUser } from '../../redux/user/selectors';
import { fetchAllPatients } from '../../services/api';
import request from '../../services/request';
import { isPatient } from '../../services/rights';

function MedicalFileCreate() {
  const { t } = useTranslation();
  const history = useHistory();
  const medicaleFileTypes = useMedicaleFileType();
  const [state, setState] = useState({ isFetching: false, err: null, type: null });
  const [patients, setPatients] = useState([]);
  const [user] = useUser();
  const roles = useRoles();
  const [form] = Form.useForm();
  const hasPermission = useHasPermission();

  const fetchUsers = useCallback(() => {
    fetchAllPatients(user)
      .then((s) => setPatients(s))
      .catch(() => setPatients([]));
  }, [user]);

  useEffect(() => {
    if (!isPatient(roles)) fetchUsers();
  }, [fetchUsers, roles]);

  const create = async (values) => {
    try {
      setState({ isFetching: true });
      const { data } = await request('/medicalfiles', 'POST', {
        ...values,
        recoverystay: state.type === 1 ? values.recoverystay || false : undefined,
        visa: state.type === 1 ? values.visa || false : undefined,
      });
      history.push(`/medicals/${data.reference}`);
    } catch (err) {
      setState({ isFetching: false, err, type: state.type });
    }
  };

  const handleChange = (val) => {
    window.scrollTo(0, 0);
    form.setFieldsValue({ type: val });
    setState((s) => ({ ...s, type: val }));
    // if (!hasPermission('create medicalfiles as') && val === 3) form.submit();
  };

  const resetType = () => {
    setState((s) => ({ ...s, type: null }));
  };
  if (isPatient(roles) && !user.profile) {
    message.info(t('FRONT_NOTIFICATION_COMPLETE_PROFILE'));
    return <Redirect to="/profile" />;
  }
  return (
    <div>
      <Title>
        {state.type !== null ? medicaleFileTypes[state.type] : t('FRONT_MEDICAL_FILE_TYPE_CHOICE')}
      </Title>
      <Form form={form} layout="vertical" onFinish={create}>
        <Form.Item name="type" shouldUpdate hidden={state.type !== null}>
          <MedicalFileTypeRadio onChange={handleChange} />
        </Form.Item>
        {state.type !== null && (
          <>
            <Button type="link" onClick={resetType} icon={<ArrowLeftOutlined />}>
              {t('FRONT_MEDICAL_FILES_CHANGE_TYPE')}
            </Button>
            {hasPermission('create medicalfiles as') && (
              <Form.Item label={t('FRONT_MEDICAL_FILE_SELECT_PATIENT')} name="user_id" required>
                <Select
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  {patients.map((m) => (
                    <Select.Option key={m.id} value={m.id}>
                      {`${m.firstname} ${m.lastname} (${m.email})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </>
        )}
        {(state.type === 0 || state.type === 1) && <SelectPathology />}
        {state.type === 1 && <T4T />}
        {state.type === 2 && <SelectCheckupType />}

        <FormErrors err={state.err} />

        {state.type !== null && (
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={state.isFetching}>
              {t('FRONT_FORM_SAVE')}
            </Button>
          </Form.Item>
        )}
      </Form>
    </div>
  );
}

export default MedicalFileCreate;
