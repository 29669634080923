import { Form, Select, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import request from '../../services/request';

function SelectCities() {
  const { t } = useTranslation();
  const [state, setState] = useState({ cities: [], isFetching: true });

  useEffect(() => {
    request('/checkup/cities').then((c) => {
      setState({ cities: c.data, isFetching: false });
    });
    return () => {
      setState({ cities: [], isFetching: true });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isFetching) return <Skeleton />;

  return (
    <Form.Item
      label={t('FRONT_CITY_LABEL')}
      name="city"
      rules={[{ required: true, message: t('FRONT_FORM_MESSAGE_REQUIRED') }]}
    >
      <Select
        showSearch
        filterOption={(input, option) =>
          option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {state.cities.map((c, i) => (
          <Select.Option key={`city-${i}`} value={c}>
            {c}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectCities;
