/* eslint-disable react/prop-types */
import { PDFViewer } from '@react-pdf/renderer';
import { Collapse } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import MedicalFileDetails from '../../../pages/medicalFile/Details';
import Patient from '../../../pages/medicalFile/Patient';
import Summary from '../../../pages/medicalFile/Summary';
import Contact from '../../../pages/medicalFile/T4T/Contact';
import { useRoles } from '../../../redux/user/selectors';
import {
  isAdmin,
  isClinicCoordinator,
  isExpertDoctor,
  isIntermediateCoordinator,
} from '../../../services/rights';
import Comments from '../../medicalFile/Comments';
import HealthForm from '../../medicalFile/HealthForm';
import MedicalFileDocuments from '../../../pages/medicalFile/T4T/Documents';

function OCRouter({ medicalFile }) {
  const roles = useRoles();
  const { t } = useTranslation();
  return (
    <Switch>
      <Route path="/medicals/:reference" exact>
        <MedicalFileDetails medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/patient" exact>
        <Patient medicalFile={medicalFile} />
        {(isExpertDoctor(roles) ||
          isClinicCoordinator(roles) ||
          isAdmin(roles) ||
          isIntermediateCoordinator(roles)) && (
          <Collapse style={{ marginTop: 20 }}>
            <Collapse.Panel header={t('FRONT_MEDICAL_FILE_COMMENTS_PATIENT')} key={4}>
              <Comments url={`/medicalfiles/${medicalFile.reference}/comments `} />
            </Collapse.Panel>
          </Collapse>
        )}
      </Route>
      <Route path="/medicals/:reference/health" exact>
        <HealthForm medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/contact" exact>
        <Contact medicalFile={medicalFile} />
      </Route>
      <Route path="/medicals/:reference/summary" exact>
        <Summary medicalFile={medicalFile} />
      </Route>
      <Route>
        <MedicalFileDetails medicalFile={medicalFile} />
      </Route>
    </Switch>
  );
}

export default OCRouter;
