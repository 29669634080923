import {
  AuditOutlined,
  BankOutlined,
  CloseCircleOutlined,
  ContactsOutlined,
  FolderAddOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  SettingOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Affix, Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useHasPermission from '../../hooks/useHasPermission';
import { signOut } from '../../redux/user/actions';
import { useRoles } from '../../redux/user/selectors';
import { isOnAxaWebsite, url } from '../../services/request';
import { isPatient } from '../../services/rights';

const { Sider: ANTDSider } = Layout;
const { SubMenu } = Menu;

function Sider() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const hasPermission = useHasPermission();
  const roles = useRoles();

  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (v) => setCollapsed(v);

  return (
    <Affix offsetTop={64}>
      <ANTDSider
        width={200}
        collapsed={collapsed}
        onCollapse={onCollapse}
        breakpoint="lg"
        collapsible
        className="sider__container"
      >
        <Menu
          mode="inline"
          selectedKeys={[location.pathname]}
          defaultOpenKeys={['/users', '/patients', '/entities', '/medicals']}
        >
          {isPatient(roles) && (
            <Menu.Item
              icon={<UserOutlined />}
              key="/profile"
              onClick={() => history.push('/profile')}
            >
              {t('FRONT_SIDEBAR_PROFILE')}
            </Menu.Item>
          )}

          {isPatient(roles) && (
            <Menu.Item
              icon={<SettingOutlined />}
              key="/account"
              onClick={() => history.push('/account')}
            >
              {t('FRONT_SIDEBAR_ACCOUNT')}
            </Menu.Item>
          )}

          {isPatient(roles) && (
            <Menu.Item
              icon={<AuditOutlined />}
              key="/documents"
              onClick={() => history.push('/documents')}
            >
              {t('FRONT_SIDEBAR_USER_DOCUMENTS')}
            </Menu.Item>
          )}

          {hasPermission('view companies') && (
            <SubMenu icon={<BankOutlined />} key="/entities" title={t('FRONT_SIDEBAR_ENTITIES')}>
              <Menu.Item
                icon={<UnorderedListOutlined />}
                key="/entities"
                onClick={() => history.push('/entities')}
              >
                {t('FRONT_SIDEBAR_FULL_LIST')}
              </Menu.Item>
              {hasPermission('create companies') && (
                <Menu.Item
                  icon={<UserAddOutlined />}
                  key="/entities/add"
                  onClick={() => history.push('/entities/add')}
                >
                  {t('FRONT_SIDEBAR_CREATE')}
                </Menu.Item>
              )}
            </SubMenu>
          )}
          {hasPermission('view users') && (
            <SubMenu icon={<TeamOutlined />} key="/users" title={t('FRONT_SIDEBAR_USERS')}>
              <Menu.Item
                icon={<UnorderedListOutlined />}
                key="/users"
                onClick={() => history.push('/users')}
              >
                {t('FRONT_SIDEBAR_FULL_LIST')}
              </Menu.Item>

              {hasPermission('create users') && (
                <Menu.Item
                  icon={<UserAddOutlined />}
                  key="/users/add"
                  onClick={() => history.push('/users/add')}
                >
                  {t('FRONT_SIDEBAR_CREATE')}
                </Menu.Item>
              )}
            </SubMenu>
          )}
          {hasPermission('view patients') && (
            <SubMenu icon={<TeamOutlined />} key="/patients" title={t('FRONT_SIDEBAR_PATIENTS')}>
              <Menu.Item
                icon={<UnorderedListOutlined />}
                key="/patients"
                onClick={() => history.push('/patients')}
              >
                {t('FRONT_SIDEBAR_FULL_LIST')}
              </Menu.Item>

              {hasPermission('create patients') && (
                <Menu.Item
                  icon={<UserAddOutlined />}
                  key="/patients/add"
                  onClick={() => history.push('/patients/add')}
                >
                  {t('FRONT_SIDEBAR_CREATE')}
                </Menu.Item>
              )}
            </SubMenu>
          )}
          {hasPermission('view medicalfiles') && (
            <SubMenu
              icon={<ContactsOutlined />}
              key="/medicals"
              title={t('FRONT_SIDEBAR_MEDICALS')}
            >
              <Menu.Item
                icon={<UnorderedListOutlined />}
                key="/medicals"
                onClick={() => history.push('/medicals')}
              >
                {t('FRONT_SIDEBAR_FULL_LIST')}
              </Menu.Item>
              {hasPermission('create medicalfiles') && (
                <Menu.Item
                  icon={<FolderAddOutlined />}
                  key="/medicals/add"
                  onClick={() => history.push('/medicals/add')}
                >
                  {t('FRONT_SIDEBAR_CREATE')}
                </Menu.Item>
              )}
              <Menu.Item
                icon={<SearchOutlined />}
                key="/medicals/search"
                onClick={() => history.push('/medicals/search')}
              >
                {t('FRONT_SIDEBAR_SEARCH')}
              </Menu.Item>
            </SubMenu>
          )}

          {isPatient(roles) && (
            <Menu.Item
              icon={<QuestionCircleOutlined />}
              key="/faq"
              disabled
              onClick={() => history.push('/faq')}
            >
              {t('FRONT_SIDEBAR_FAQ')}
            </Menu.Item>
          )}

          <Menu.Item
            icon={<InfoCircleOutlined />}
            key="/policy"
            onClick={() => history.push('/policy')}
          >
            {t('FRONT_SIDEBAR_PRIVACY_POLICY')}
          </Menu.Item>

          <Menu.Item
            icon={<InfoCircleOutlined />}
            key="/terms"
            onClick={() => history.push('/terms')}
          >
            {t('FRONT_SIDEBAR_LEGAL_TERMS')}
          </Menu.Item>

          <Menu.Item
            icon={<CloseCircleOutlined />}
            onClick={() => {
              if (isOnAxaWebsite()) window.location.href = `${url}/auth0/logout`;
              else dispatch(signOut());
            }}
          >
            {t('FRONT_COMMON_SIGN_OUT')}
          </Menu.Item>
        </Menu>
      </ANTDSider>
    </Affix>
  );
}

export default Sider;
