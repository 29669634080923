import React from 'react';
import PropTypes from 'prop-types';
import { Link, Text, View, StyleSheet, Svg, Polygon, Rect, Image } from '@react-pdf/renderer';
import manSrc from '../../../assets/images/man.png';
import womanSrc from '../../../assets/images/woman.png';
import dotPositions from './dotPositions.json';

const styles = StyleSheet.create({
  section: {
    width: '100%',
    position: 'relative',
  },
  img: {
    height: 300,
    width: 300,
    margin: 'auto',
  },
  dot: {
    position: 'absolute',
    width: 10,
    height: 10,
    borderRadius: '50%',
  },
  ...dotPositions,
});

const Dot = (props) => {
  const { color, label } = props;
  if (!color || !label) {
    return null;
  }
  return <View style={[styles.dot, styles[label][color]]} />;
};

Dot.propTypes = {
  color: PropTypes.oneOf(['red', 'orange', 'green']).isRequired,
  label: PropTypes.string.isRequired,
};

const Chart = (props) => {
  const { dots, gender } = props;

  const imgSrc = gender === 'man' ? manSrc : womanSrc;
  return (
    <View style={styles.section}>
      <Image src={imgSrc} style={styles.img} />
      {dots.map((dot, key) => (
        <Dot key={`dot_${key}`} color={dot.color} label={dot.label} />
      ))}
    </View>
  );
};

Chart.propTypes = {
  dots: PropTypes.arrayOf(Dot).isRequired,
  gender: PropTypes.string.isRequired,
};

export default Chart;
