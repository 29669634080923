import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Workflow from '..';
import { useMedicalFile } from '../../../redux/medicalFile/selectors';
import { fetchStep } from '../../../redux/step/actions';
import { useStep } from '../../../redux/step/selectors';
import Loading from '../../layouts/Loading';

function OCWorflow() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [, isFetching] = useStep();
  const [medicalFile] = useMedicalFile();

  useEffect(() => {
    dispatch(fetchStep(medicalFile.reference));
  }, [dispatch, medicalFile.reference]);

  const steps = useMemo(
    () => [
      {
        reference: 'patient.stepfillmedicalform',
        next: () => history.push(`/medicals/${medicalFile.reference}/health`),
      },
      {
        reference: 'system.fileclosed',
        next: null,
      },
    ],
    [history, medicalFile]
  );
  if (isFetching) return <Loading />;
  return <Workflow steps={steps} />;
}

export default OCWorflow;
