/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import { Form, Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchAllUsers } from '../../services/api';

function Interlocutor({ multiple = false, label, roleName, type = null }) {
  const [[users, isFetching], setUsers] = useState([[], true]);

  const loadUsers = useCallback(async () => {
    const u = await fetchAllUsers(roleName).catch(() => []);
    setUsers([u, false]);
  }, [roleName]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return (
    <Form.Item label={label} name={type || roleName}>
      <Select
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        mode={multiple ? 'multiple' : ''}
        onClear={(v) => null}
        showSearch
        allowClear
        loading={isFetching}
      >
        {users.map((m) => (
          <Select.Option key={m.id} value={m.id}>
            {`${m.firstname} ${m.lastname} (${m.email})`}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default Interlocutor;
